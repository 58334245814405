<template>
    <div class="Profile-wrapper">
        <v-container v-if="$auth.ready()">
            <v-row justify="center">
                <v-col class='text-center' cols="2">
                    <router-link :to="{name: 'user.setting'}" class="linked">
                        <app-icon class="top-icon" icon="cog"/>
                    </router-link>
                </v-col>
                <v-col cols="8" class="pt-2 text-center profile-switch">
                    <v-menu close-on-content-click offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="elevation-0"
                                v-bind="attrs" v-on="on" >
                                <span class="text-lowercase">
                                    {{ user.name }}
                                </span>
                                <v-icon size="30">mdi-menu-down</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                            v-for="(item, i) in accountOptions"
                            :key="i"
                            @click="handleSwitchAccount(item.id)"
                            >
                                <div class="d-flex align-center profile-account-lists">
                                    <user-image
                                        :src="item.img"
                                        :username="item.name"
                                    />
                                    <span>{{ item.name }}</span>
                                    <verified-icon
                                        v-if="item.is_verified" 
                                        :height="15" :width="15" class="ml-1" />
                                    <v-icon v-if="item.name === user.name" class="success--text">mdi-check-circle</v-icon>
                                </div>
                            </v-list-item>

                            <v-divider></v-divider>

                            <v-list-item
                                @click="handleAddAccount"
                                class="justify-center" >
                                <span>{{ $t('dialogs.switch_account.add_account_label') }}</span>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
                <v-col class='text-center' cols="2">
                    <router-link :to="{name: 'user.own.messages'}" class="linked">
                        <v-badge
                            v-if="isActiveMessages"
                            :content="unreadMessagesStr"
                            color="#FE4811"
                            bottom
                            offset-x="14"
                            offset-y="16"
                        >
                            <app-icon icon="telegram" :active="isActiveMessages"/>
                        </v-badge>
                        <app-icon v-else icon="telegram" />
                    </router-link>
                </v-col>
            </v-row>
            <v-row justify="center">
                <profile-image
                    :img="userData.data.profileImage"
                    :username="user.name"
                    size="136"
                    :has-not-watched-stories="user.hasNotWatchedStories"
                    :is-exist-story="user.isExistStory"
                    class="mt-6"
                    :id="user.id"
                    :editable="user.isExistStory"
                    @click="handleClickProfile"
                />
            </v-row>
            <v-row justify="center">
                <div class="name d-flex justify-center align-center">
                    <span>
                        {{ user.name }}
                    </span>
                    <verified-icon
                        v-if="user.verified"
                        :height="24" :width="24" class="ml-2" />
                        <holiday-mood-icon v-if="userData.data.on_holiday_mood"/>
                </div>
            </v-row>
            <v-row justify="center">
                <v-col cols="4" class="ma-7 mt-4 text-end">
                    <router-link :to="{name: 'user.profile.followers', params: {id: user.id}}"
                                 class="link text-decoration-none">
                        <div class="text-center">
                        <span class="counters">
                           {{ user.followersCount }}
                        </span>
                            <span class="hint">{{ $t('pages.profile.main.followers') }}</span>
                        </div>
                    </router-link>
                </v-col>
                <v-col cols="3" class="ma-7 mt-4">
                    <router-link :to="{name: 'user.profile.following', params: {id: user.id}}"
                                 class="link text-decoration-none">
                        <div class="text-center">
                            <span class="counters"> {{ user.followingCount }} </span>
                            <span class="hint">{{ $t('pages.profile.main.following') }}</span>
                        </div>
                    </router-link>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <router-link :to="{name: 'user.own.profile.info'}" class="link text-decoration-none">
                        <v-row justify="center" align="center">
                            <v-col cols="2">
                                <app-icon icon="write"/>
                            </v-col>
                            <v-col cols="8">
                                {{ $t('pages.profile.main.info') }}
                            </v-col>
                            <v-col cols="2" class="text-end">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <router-link :to="{name: 'user.setting.creator'}" class="link text-decoration-none">
                        <v-row justify="center" align="center">
                            <v-col cols="2">
                                <app-icon icon="dollar"/>
                            </v-col>
                            <v-col cols="8">
                                {{ $t('pages.profile.main.creator') }}
                            </v-col>
                            <v-col cols="2" class="text-end">
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-col>
                        </v-row>
                    </router-link>
                </v-col>
            </v-row>
            <v-row v-if="isPWAInstalled == false && isEventCall == false" no-gutters justify="center">
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="2">
                            <app-icon icon="write"/>
                        </v-col>
                        <v-col cols="8" @click="openInstallDialog = true">
                            {{ $t('pages.profile.main.get_app') }}
                        </v-col>
                        <v-spacer/>
                        <app-transition>
                            <instruction-popup v-if="openInstallDialog" @yes="confirmInstallApp" @no="openInstallDialog = false">
                            </instruction-popup>
                        </app-transition>
                        <v-col cols="2">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="isPWAInstalled == false && isEventCall == true" no-gutters justify="center">
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="2">
                            <app-icon icon="write"/>
                        </v-col>
                        <v-col cols="8" @click="openInstallDialog = true">
                            {{ $t('pages.profile.main.get_app') }}
                        </v-col>
                        <v-spacer/>
                        <app-transition>
                            <yes-no-dialog v-if="openInstallDialog" @yes="confirmInstallApp" @no="openInstallDialog = false">
                                {{ $t('dialogs.are_you_sure') }}
                            </yes-no-dialog>
                        </app-transition>
                        <v-col cols="2">
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center">
                <v-col cols="12">
                    <v-row justify="center" align="center">
                        <v-col cols="2">
                            <app-icon icon="share" @click="share"/>
                        </v-col>
                        <v-col cols="8">
                            <span ref="userlinkFull" :title="userlinkFull">{{ marketUserText }}</span>
                        </v-col>
                        <v-col cols="2" class="text-end" @click="copyLink">
                            <app-icon icon="copy"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <bottom-bar :active="activeBottomBar" :model="false"></bottom-bar>
        <inform-dialog
            :text="$t('dialogs.not_contents')"
            v-if="this.isNotHaveContents"
            @close="handleCloseInformDialog"
        />

        <add-account-dialog
            v-if="showAddAccountDialog"
            @close="showAddAccountDialog = false"
            @login="loginToExistingAccount"
            @signup="createNewAccount"
        />
    </div>
</template>

<script>
import BottomBar from "@/components/app/navigation/BottomBar";
import {mapActions, mapMutations, mapState} from 'vuex'
import ProfileImage from "@/components/app/profile/ProfileImage";
import InformDialog from "@/components/app/dialogs/InformDialog";
import VerifiedIcon from "@/components/app/common/VerifiedIcon";
import InstructionPopup from "@/components/app/profile/InstructionPopup";
import YesNoDialog from "@/components/app/setting/YesNoDialog";
import AddAccountDialog from "@/components/app/dialogs/AddAccountDialog";
import UserImage from "@/components/app/notifications/ProfileImage";

import * as clipboardy from 'clipboardy'
import { DOMAIN_NAME, SERVER_PROTOCOL, LINK_ACCOUNT_KEY, PARENT_ACCOUNT_KEY } from "@/configs/constants";
import { setCookie } from "@/helpers/functions";
import HolidayMoodIcon from "@/components/app/icons/HolidayMoodIcon";

export default {
    name: "Profile.vue",
    data() {
        return {
            isShowEditStory: false,
            isNotHaveContents: false,
            openDialog: null,
            openInstallDialog: null,
            deferredPrompt: null,
            isPWAInstalled: true,
            isEventCall:false,
            showAddAccountDialog: false,
        }
    },
    computed: {
        ...mapState({
            userData: state => state.user.user,
        }),
        user() {
            return this.$auth.user()
        },
        userlink() {
            return '/' + this.user.slug
        },
        userlinkFull() {
            return `${SERVER_PROTOCOL}://${DOMAIN_NAME}${this.userlink}?referral_code=${this.userData.referralCodes[0]?.value}`
        },
        marketUserText() {
            const userText = 'NOODZLY' + this.userlink
            return userText.toUpperCase()
        },
        unreadMessagesStr() {
            return this.unreadMessages > 9 ? '9+' : `${this.unreadMessages}`
        },
        unreadMessages() {
            return this.unread_messages;
        },
        isActiveMessages() {
            return this.unreadMessages > 0
        },
        accountOptions() {
            return [
                { id: this.user.id, name: this.user.name, img: this.user.profileImage, is_verified: this.user.verify_at },
                ...this.user.linked_accounts.map(account => ({
                    id: account.id,
                    name: account.name,
                    img: account.avatar_url,
                    is_verified: account.verify_at
                })),
            ];
        },
        ...mapState({
            unread_messages: state => state.user.user.data.unread_messages,
            activeBottomBar: state => state.site.activeBottomBar
        })
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        ...mapActions(['getUserStories', 'getReferrals', 'switchAccount', 'checkAddAccountPermission']),
        share() {
            const shareData = {
                title: this.marketUserText,
                text: 'Check my profile',
                url: this.userlinkFull,
            }
            if (typeof navigator.share !== 'undefined')
                navigator.share(shareData)
                    .then(() => {
                        console.log('shared', shareData)
                    })
                    .catch(() => {
                        console.log('reject', shareData)
                    })
        },
        copyLink() {
            clipboardy.write(this.userlinkFull)
            this.showSnackBar(this.$t('dialogs.copied_clipboard'))
        },
        handleCloseEditStory() {
            this.isShowEditStory = false
        },
        handleCloseInformDialog() {
            this.isNotHaveContents = false
        },
        handleClickProfile() {
            if (!this.user.isExistStory) {
                this.isNotHaveContents = true
            }
        },
        
        closeInstallDialog() {
            this.openInstallDialog = null;
        },

        confirmInstallApp() {
            if (this.deferredPrompt) {
                this.deferredPrompt.prompt(); // Show the install prompt
                this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('PWA installation accepted');
                } else {
                    console.log('PWA installation dismissed');
                }
                this.deferredPrompt = null;
                });
            }
            this.closeInstallDialog();
        },
        checkPWAInstallation() {
            if (navigator.standalone) {
                this.isPWAInstalled = true;
            }
            else if (window.matchMedia('(display-mode: standalone)').matches) {
                this.isPWAInstalled = true;
            }
            else {
                console.log("PWA is not installed");
                this.isPWAInstalled = false;
            }
        },
        async handleAddAccount() {
            try {
                await this.checkAddAccountPermission();
                this.showAddAccountDialog = true;
            } catch (error) {
                this.showSnackBar(error)
            }
        },
        async handleSwitchAccount(userId){
            try {
                if (userId !== this.user.id) {
                    await this.setParentAccountKey();
                    await this.switchAccount(userId);
                    this.showSnackBar('Account switched successfully');
                }
            } catch (error) {
                this.showSnackBar(error)
            }
        },
        loginToExistingAccount() {
            setCookie(LINK_ACCOUNT_KEY, this.user.name, 1);
            this.setParentAccountKey();
            this.$router.push({ name: 'login' });
        },
        createNewAccount() {
            setCookie(LINK_ACCOUNT_KEY, this.user.name, 1);
            this.setParentAccountKey();
            this.$router.push({ name: 'sign-up' });
        },
        setParentAccountKey(){
            setCookie(PARENT_ACCOUNT_KEY, this.user.slug, 1 * 24 * 7);
        },
    },
    components: {
        ProfileImage,
        BottomBar,
        InformDialog,
        VerifiedIcon,
        InstructionPopup,
        YesNoDialog,
        AddAccountDialog,
        UserImage,
        HolidayMoodIcon,
    },
    async mounted() {
        await this.getReferrals();
    },
    created() {
        const userId = this.$auth.user().id
        this.$echo.private(`user.${userId}`).listen('.story.processed', this.getUserStories)
        this.checkPWAInstallation()
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            this.deferredPrompt = event;
            console.log("beforeinstallprompt fired in created");
            this.isEventCall = true
        });

        this.$mixpanel.identifyUser(this.userData.data.email);
        this.$mixpanel.pageViewed('Account');
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.Profile {
    &-wrapper {
        min-height: 100%;
    }
}

.top-icon {
    font-size: 28px;
}

.profile-image {
    height: 136px;
    width: 136px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: whitesmoke;
    border: 6px solid black;
    object-fit: cover;
}

.image-container {
    height: 148px;
    width: 148px;
    border-radius: 50%;
    padding-left: 6px;
    padding-top: 6px;
}

.story-exist {
    background: $accent-background;
}

.name {
    font-size: 1.5em;
}

.counters {
    font-size: 1.5em;
}

.hint {
    display: block;
    font-size: 0.8em;
    color: rgba(255, 255, 255, 0.6);
}

.linked {
    text-decoration: none;
}

.profile-account-lists {
    i {
        position: absolute;
        right: 10px;
    }
}

.profile-switch { 
   .v-btn  {
        min-width: 200px;
   }
}
</style>
